<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import simplebar from "simplebar-vue";
import WalletBalance from "./wallet-balance.vue";
// import Overview from "./overview.vue";

import {
  walletRadialChart,
  // overviewChart,
  transactionsData,
  bitconinChart,
  ethereumChart,
  litecoinChart,
} from "./data";

import ApiClass from "../../../api/api";
/**
 * Crypto Dashboard Component
 */
export default {
  page: {
    title: "Crypto Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, WalletBalance, simplebar },
  data() {
    return {
      id: "",
      walletRadialChart: walletRadialChart,
      // overviewChart: overviewChart,
      transactionsData: transactionsData,
      bitconinChart: bitconinChart,
      ethereumChart: ethereumChart,
      litecoinChart: litecoinChart,

      title: "Crypto",
      items: [
        {
          text: "Dashboards",
          href: "/admin",
        },
        {
          text: "Crypto",
          active: true,
        },
      ],
      userInfo: [],
      walletInfo: [],
      cryptoInfo: [],
      ordersInfo: [],
      walletTransactionInfo: [],
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.userList();
  },
  methods: {
    async userList() {
      var result = await ApiClass.getRequest(
        "admin/users/get/" + this.id,
        true
      );
      if (result.data.status_code == 1) {
        this.userListData = result.data.data;
        this.userInfo = this.userListData.user;

        this.walletInfo = this.userListData.wallet;
        this.cryptoInfo = this.userListData.crypto;
        this.ordersInfo = this.userListData.orders;
        this.walletTransactionInfo = this.userListData.walletTransaction;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card users_wallet_card">
          <div class="card-body">
            <b-dropdown
              class="float-end ms-2"
              variant="white"
              right
              toggle-class="text-muted p-0"
              menu-class="dropdown-menu-end"
            >
              <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here</b-dropdown-item>
            </b-dropdown>
            <div>
              <div class="mb-4 me-3">
                <i class="mdi mdi-account-circle text-primary h1"></i>
              </div>

              <div>
                <h5>{{ userInfo.name }}</h5>
                <p class="text-muted mb-1">{{ userInfo.email }}</p>
                <p class="text-muted mb-0">{{ walletInfo.address }}</p>
              </div>
              <hr />
            </div>

            <div>
              <div>
                <h5>Wallet's</h5>
                <div
                  class="text-muted mb-1"
                  v-for="(data, index) in walletInfo"
                  :key="index"
                >
                  <h6>
                    {{ data.type }} :
                    <span class="fw-lighter">{{ data.address }}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body border-top">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <p class="fw-medium mb-2">Balance :</p>
                  <h4>$ {{ walletInfo.balance }}</h4>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mt-4 mt-sm-0">
                  <p class="fw-medium mb-2">Coin :</p>
                  <div class="d-inline-flex align-items-center mt-1"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer bg-transparent border-top">
            <div class="text-center">
              <a
                href="javascript: void(0);"
                class="btn btn-outline-light me-2 w-md"
                >Deposit</a
              >
              <a href="javascript: void(0);" class="btn btn-primary me-2 w-md"
                >Buy / Sell</a
              >
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-xl-8">
        <WalletBalance />
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Orders</h4>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap align-middle">
                <thead>
                  <tr class="align-middle">
                    <th style="width: 50px" scope="col">Type</th>
                    <th scope="col">Currency</th>
                    <th scope="col">
                      <div>
                        <h5 class="font-size-14 mb-0">
                          Qty/<span>Price</span>
                        </h5>
                      </div>
                    </th>
                    <th scope="col">Total</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="ordersInfo.length == 0">
                    <td colspan="5">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <h5>No Record Found</h5>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(data, index) of ordersInfo" :key="index">
                    <td style="width: 50px">
                      <div
                        :class="`font-size-22 text-${
                          data.order_type == 'buy' ? 'success' : 'danger'
                        }`"
                      >
                        <i
                          :class="{
                            'bx bx-down-arrow-circle':
                              `${data.order_type}` === 'buy',
                            'bx bx-up-arrow-circle':
                              `${data.order_type}` === 'sell',
                          }"
                        ></i>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h5 class="font-size-14 mb-1">
                          {{ data.currency }}/<span>{{
                            data.with_currency
                          }}</span>
                        </h5>
                        <p class="text-muted mb-0">
                          {{ new Date(data.created_at).toLocaleString() }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h5 class="font-size-14 mb-1">{{ data.quantity }}</h5>
                        <p class="text-muted mb-0">{{ data.at_price }}</p>
                      </div>
                    </td>
                    <td>{{ data.total }}</td>
                    <td>{{ data.current_status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="ordersInfo.length > 5">
              <router-link :to="'/crypto/orders/' + id">
                <button type="button" class="btn btn-primary w-sm">
                  View More
                  <i class="mdi mdi-arrow-right ms-1"></i>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Wallet Transactions</h4>
            <div class="user_data_list">
              <div class="table-responsive">
                <table class="table table-centered table-nowrap align-middle">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="walletTransactionInfo.length == 0">
                      <td colspan="5">
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                        >
                          <h5>No Record Found</h5>
                        </div>
                      </td>
                    </tr>

                    <tr
                      v-for="(data, index) in walletTransactionInfo"
                      :key="index"
                    >
                      <td style="width: 50px">
                        <svg
                          v-if="data.type == 'deposit'"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 38.184 38.184"
                        >
                          <path
                            id="Icon_open-arrow-circle-top"
                            data-name="Icon open-arrow-circle-top"
                            d="M18,4.5,31.5,18h-9V31.5h-9V18h-9Z"
                            transform="translate(44.548 19.092) rotate(135)"
                            fill="#34c38f"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 38.184 38.184"
                        >
                          <path
                            id="Icon_open-arrow-circle-top"
                            data-name="Icon open-arrow-circle-top"
                            d="M18,4.5,31.5,18h-9V31.5h-9V18h-9Z"
                            transform="translate(-6.364 19.092) rotate(-45)"
                            fill="#f46a6a"
                          />
                        </svg>
                      </td>
                      <td>{{ data.type }}</td>
                      <td>{{ data.amount }}</td>
                      <td>
                        <span
                          :class="`badge bg-${
                            data.status == 'completed' ? 'success' : 'warning'
                          }`"
                          >{{ data.status }}</span
                        >
                      </td>
                      <td>
                        {{ new Date(data.created_at).toLocaleString() }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center" v-if="walletTransactionInfo.length > 5">
              <router-link to="/crypto/wallettransactions/ ">
                <button type="button" class="btn btn-primary w-sm">
                  View More
                  <i class="mdi mdi-arrow-right ms-1"></i>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Cryptos</h4>
            <div class="user_data_list">
              <simplebar style="max-height: 335px">
                <div class="table-responsive">
                  <table class="table table-centered table-nowrap align-middle">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="cryptoInfo.length == 0">
                        <td colspan="3">
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <h5>No Record Found</h5>
                          </div>
                        </td>
                      </tr>

                      <tr v-for="(data, index) in cryptoInfo" :key="index">
                        <td style="width: 100px">
                          <img
                            :src="data.image"
                            class="rounded-circle avatar-xs"
                            alt
                          />
                        </td>

                        <td>
                          <h5 class="font-size-14 mb-0">
                            {{ data.currency }}
                          </h5>
                        </td>
                        <td>{{ data.balance }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </simplebar>
            </div>
            <div class="text-center mt-4" v-if="cryptoInfo.length > 5">
              <button type="button" class="btn btn-primary w-sm">
                View More
                <i class="mdi mdi-arrow-right ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.card.users_wallet_card {
  min-height: 355px !important;
  height: auto !important;
}
</style>