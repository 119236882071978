<script>
import ApiClass from "../../../api/api";


export default {
  props:["title","walletcall"],
  data() {
    return {
      callurl: "",
      loading: false,
      walletData : [],
      chartOptions: {
        chart: {
          height: 300,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "50%",
              background: "transparent",
              image: undefined,
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: "#f2f2f2",
              strokeWidth: "10%",
              opacity: 1,
              margin: 12,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "16px",
                fontWeight: 600,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: "14px",
                offsetY: 4,
                formatter: function (val) {
                  return val + "%";
                },
              },
              total: {
                show: true,
                label: "Total",
                color: "#373d3f",
                fontSize: "16px",
                fontFamily: undefined,
                fontWeight: 600,
                formatter: function (w) {
                  return (
                    w.globals.seriesTotals.reduce(function (a, b) {
                      return Math.round(a + b);
                    }, 0) + "%"
                  );
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        colors: [],
        labels: [],
        legend: {
          show: false,
        },
      },
    };
  },
  mounted(){
    this.callOnLoad();
  },
  methods: {
    callOnLoad(){
      this.callurl = (this.walletcall == "top3") ? "admin/total/top3withdrawcoin/" : "admin/total/depositwithdraw/";
      this.getresult("day",this.callurl)
    },
    async getresult(timeslot,url){
      this.loading = true;
      var result = await ApiClass.getRequest(url+timeslot, true);
      if(result.data.status_code == 1){
       this.walletData = (result.data.data) ? result.data.data : [];
       this.chartOptions.labels = (result.data.data) ? result.data.data[0].labels : [];
       this.chartOptions.colors = (result.data.data) ? result.data.data[0].colors : [];
       this.loading = false;
      }
    },
    onChange(event) {
      this.getresult(event.target.value,this.callurl);
      return;
    },
  },
};
</script>

<template>
  <div>
    <div class="card"> 
      <div class="card-body">
        <!-- {{ data }} -->
        <div class="float-end">
          <select
            class="form-select form-select-sm ms-2"
            @change="onChange($event)"
          >
            <option value="day" selected>Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </select>
        </div>
        <h4 class="card-title mb-3">{{ title }} </h4>


        <div class="text-center" v-if="loading">
          <b-spinner label="Spinning"></b-spinner>
        </div>

        <div v-else>
          <div v-if="walletData.length == 0">
            <span>Don't have any {{ title }} </span>
          </div>

          <div class="row" v-for="(data, index) of walletData" :key="index" v-else>

            <!-- Chart loop -->
            <div class="col-lg-6 col-sm-6">
              <div>
                <apexchart
                  class="apex-charts"
                  dir="ltr"
                  type="radialBar"
                  height="300"
                  :series="data.chartSeries"
                  :options="chartOptions"
                ></apexchart>
              </div>
            </div>
            <!-- Chart loop -->



            <!-- list of coins  -->
            <div class="col-lg-4 col-sm-6 align-self-center">
              <div
                v-for="(data, i) of data.balancelist"
                :key="i"
                class="mt-4 pt-2"
              >
                <p class="mb-2">
                  <i
                    class="
                      mdi mdi-circle
                      align-middle
                      font-size-10
                      me-2
                    "
                    :style="'color:'+data.color"
                  ></i>
                  {{ data.text }}
                </p>
                <h5>
                  {{ data.coin }}  {{ data.text }}=
                  <span class="text-muted font-size-14">{{ data.amount_format }}</span>
                </h5>
              </div>
            </div>
            <!-- list of coins end. -->

          </div>
        </div>

      
      </div>
    </div>
  </div>
</template>
